import React from "react";
import styled from "styled-components";
import { NavLink, Link } from "react-router-dom";
import { fluidRange } from "polished";

import pmMark from "@common/website/assets/svg/pm_mark_squared.svg";
import avatarPlaceholderIcon from "@common/admin/assets/svg/avatar_placeholder_icon.svg";

const Topbar = () => (
	<Container>
		<LogoContainer>
			<Logo to="/" dangerouslySetInnerHTML={{ __html: pmMark }} />
			<LogoText>Admin</LogoText>
		</LogoContainer>
		<Center>
			<CenterInner>
				<Nav>
					<NavItem exact to="/">
						Oversikt
					</NavItem>
					<NavItem exact to="/kontorer">
						Kontorer
					</NavItem>
					<NavItem exact to="/meglere">
						Meglere
					</NavItem>
					<NavItem exact to="/eiendommer">
						Eiendommer
					</NavItem>
					<NavItem to="/interessenter">Interessenter</NavItem>
					<NavItem exact to="/boligvelgere">
						Boligvelgere
					</NavItem>
					<NavItem to="/queues">Jobbkøer</NavItem>
				</Nav>
			</CenterInner>
		</Center>
		<UserNav>
			{/* <Dropdown>
				<Trigger>
					<Avatar dangerouslySetInnerHTML={{ __html: avatarPlaceholderIcon }} /> Martin Haagensli
				</Trigger>
				<DropdownItem>Min konto</DropdownItem>
				<DropdownItem>Hjelp</DropdownItem>
				<DropdownItem>Logg ut</DropdownItem>
			</Dropdown> */}
		</UserNav>
	</Container>
);

const Container = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: 60px;
	padding: 0 30px 0 15px;
	background: ${({ theme }) => theme.colors.grey.dark};
`;

const LogoContainer = styled.div`
	display: inline-flex;
	align-items: center;
	height: 60px;
`;

const Logo = styled(Link)`
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 60px;
	margin-right: 12px;
	margin-top: -2px;
	z-index: 1;

	svg {
		display: block;
		width: 30px;
		height: 30px;

		path {
			fill: ${({ theme }) => theme.colors.brown.primary};
		}
	}
`;

const LogoText = styled.span`
	color: ${({ theme }) => theme.colors.brown.primary};
	font-family: "Domaine Display", sans-serif;
	font-size: 21px;
	font-weight: 700;
	line-height: 1;
`;

const Center = styled.div`
	display: inline-flex;
	position: relative;
	z-index: 0;

	@media screen and (min-width: 1375px) {
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 100%;
		width: 100%;
		margin: 0 auto;
	}
`;

const CenterInner = styled.div`
	display: inline-flex;
	align-items: center;
	height: 100%;
	width: auto;
	margin: 0 auto;
	padding: 0 24px;

	@media screen and (min-width: 1375px) {
		display: inline-flex;
		align-items: center;
		height: 100%;
		width: 1140px;
		margin: 0 auto;
		padding: 0 18px;
	}
`;

const Nav = styled.nav``;

const NavItem = styled(NavLink)`
	margin-right: 24px;
	color: ${({ theme }) => theme.colors.grey.light};
	text-decoration: none;

	&.active {
		color: #fff;
	}
`;

const UserNav = styled.div`
	display: inline-flex;
	height: 100%;
	margin-left: auto;
	z-index: 1;
`;

const Trigger = styled.span`
	display: flex;
	align-items: center;
	height: 100%;
`;

const Avatar = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 30px;
	height: 30px;
	margin-right: 12px;
	margin-top: -2px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.grey.medium};
	overflow: hidden;

	svg {
		display: flex;
		width: 24px;
		height: 24px;
		margin-top: 8px;

		path {
			fill: ${({ theme }) => theme.colors.brown.medium};
		}
	}
`;

const DropdownItem = styled.span``;

export default Topbar;
