import deepstreamClient from "deepstream.io-client-js";
import { createContext } from "react";

let HOST;
if (process.env.NODE_ENV === "production") {
	HOST = "109.239.231.12";
} else {
	HOST = "127.0.0.1";
}

const deepstream = deepstreamClient(`ws://${HOST}:6020/deepstream`);
deepstream.on("error", err => console.log(err));

const RootStore = {
	deepstream: deepstream
}

const RootContext = createContext();

if (module.hot) {
	module.hot.dispose(() => {
		deepstream.close();
	});
}

export {
	RootContext,
	RootStore
}
