import React, { useContext, useEffect } from "react";
import { hot } from "react-hot-loader";
import { Switch, Route } from "react-router-dom";
import { RootContext } from "@common/admin/state/root_store";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { normalize, fluidRange } from "polished";
import { theme } from "@common/admin/components/theme";
import Topbar from "@common/admin/components/shared/topbar";

import { Leads, Login } from "@common/admin/components/routes";

import domaineDisplayRegular from "@common/website/assets/fonts/DomaineDisplayWeb-Regular.woff";
import domaineDisplaySemibold from "@common/website/assets/fonts/DomaineDisp-Semibold.woff";
import proximaNovaLight from "@common/website/assets/fonts/ProximaNova-Light.woff";
import proximaNovaRegular from "@common/website/assets/fonts/ProximaNova-Reg.woff";
import proximaNovaSemibold from "@common/website/assets/fonts/ProximaNova-Sbold.woff";

const App = () => {
	const RootStore = useContext(RootContext);

	useEffect(() => {
		RootStore.deepstream.login();

		return () => {
			RootStore.deepstream.close();
		};
	}, []);
	return (
		<>
			<ThemeProvider theme={theme}>
				<>
					{/* <Topbar /> */}
					<Switch>
						<Route path="/interessenter" component={Leads} />
						<Route path="/login" component={Login} />
					</Switch>
					<GlobalStyle />
				</>
			</ThemeProvider>
		</>
	);
};

const GlobalStyle = createGlobalStyle`
	${normalize};

	@font-face {
		font-family: "Domaine Display";
		font-weight: normal;
		font-style: normal;
		font-display: auto;
		src: url(${domaineDisplayRegular}) format("woff");
	}

	@font-face {
		font-family: "Domaine Display";
		font-weight: 700;
		font-style: normal;
		font-display: auto;
		src: url(${domaineDisplaySemibold}) format("woff");
	}

	@font-face {
		font-family: "Proxima Nova";
		font-weight: normal;
		font-style: normal;
		font-display: auto;
		src: url(${proximaNovaRegular}) format("woff");
	}

	@font-face {
		font-family: "Proxima Nova";
		font-weight: 300;
		font-style: normal;
		font-display: auto;
		src: url(${proximaNovaLight}) format("woff");
	}

	@font-face {
		font-family: "Proxima Nova";
		font-weight: bold;
		font-style: normal;
		font-display: auto;
		src: url(${proximaNovaSemibold}) format("woff");
	}

	html {
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
	}

	*, *:before, *:after {
		box-sizing: inherit;
	}

	body {
		background: #000;
		color: #fff;
		font-family: "Proxima Nova", sans-serif;
	}

	h1,h2,h3,h4,h5,h6 {
		margin-top: 0;
	}

	h1 {
		${fluidRange(
			{
				prop: "font-size",
				fromSize: "36px",
				toSize: "48px"
			},
			"700px",
			"1140px"
		)}
	}

	ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
`;

export default hot(module)(App);
