import { css } from "styled-components";
import media from "styled-media-query";

export const theme = {
	colors: {
		grey: {
			darkest: "#1A1A1A",
			dark: "#212121",
			medium: "#3F3F3F",
			light: "#848280"
		},
		brown: {
			primary: "#A99A87",
			medium: "#5F5C5A",
			light: "#D5CCC2",
			lightest: "#F5F2EF"
		}
	},
	styles: {
		form: {},
		button: css`
			display: flex;
			align-items: center;
			justify-content: center;
			height: 48px;
			padding: 0 18px;
			background: #666;
			color: #fff;
			border: 0;
			cursor: pointer;
		`
	},
	media
};
