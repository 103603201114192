import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import Loadable from "react-loadable";
import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { setConfig } from "react-hot-loader";
import fetch from "isomorphic-unfetch";
import App from "@common/admin/components/app";
import { RootContext, RootStore } from "@common/admin/state/root_store";

setConfig({
	ignoreSFC: true,
	pureSFC: true,
	pureRender: true,
	logLevel: process.env.NODE_ENV === "production" ? "ignore-errors" : "debug"
});

const cache = new InMemoryCache().restore(window.__APOLLO_STATE__);

const client = new ApolloClient({
	cache,
	link: ApolloLink.from([
		new BatchHttpLink({
			uri: process.env.NODE_ENV === "production" ? "https://pm2019.reeltime.no/graphql" : "/graphql",
			credentials: "same-origin",
			fetch: fetch
		})
	])
});

const renderApp = () => {
	hydrate(
		<BrowserRouter>
			<ApolloProvider client={client}>
				<RootContext.Provider value={RootStore}>
					<App />
				</RootContext.Provider>
			</ApolloProvider>
		</BrowserRouter>,
		document.getElementById("root")
	);
};

window.loadApp = () => Loadable.preloadReady().then(() => renderApp());
