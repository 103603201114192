import React from "react";
import Loadable from "react-loadable";
import styled from "styled-components";
import PropTypes from "prop-types";

class Loader extends React.Component {
	static propTypes = {
		error: PropTypes.any,
		loading: PropTypes.any
	};
	componentDidCatch(err, info) {
		console.log(err);
		console.log(info);
	}
	render() {
		if (this.props.error) {
			console.log(this.props.error);
			return <LoaderContainer>{this.props.error ? JSON.stringify(this.props.error) : null}</LoaderContainer>;
		} else {
			return null;
		}
	}
}

const LoaderContainer = styled.div`
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 24px;
	text-align: center;
	background: #000;
	color: #aaa;
`;
// Home route

export const Leads = Loadable({
	loader: () => import(/* webpackChunkName: "admin_leads_index" */ "@common/admin/components/pages/leads"),
	loading: props => <Loader {...props} />
});

export const Login = Loadable({
	loader: () => import(/* webpackChunkName: "admin_login" */ "@common/admin/components/pages/account/login"),
	loading: props => <Loader {...props} />
});
